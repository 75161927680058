/* Welcome to Compass. Use this file to define print styles.
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/print.css" media="print" rel="stylesheet" type="text/css" /> */

.header-gradient-container, .region-header, .region-footer ul.menu, .field-name-share, .mobile-login, .twitter-block, .field-name-zahlen-slideshow, #block-views-beitragsart-block{
  display: none;
}

a, a:link, a:visited {
  &:after{
    content: "";
  }
}

#header_title, #page-header, .not-front header#page-header,  footer.footer .footer-block .footer-right, footer.footer, .main-container-background{
  position: static;
}

footer.footer .footer-block .footer-right{
  display: block;
  text-align: left;
}
footer.footer .footer-block .footer-right .vdr-social{
  display: none;
}
.main-container-background, .node-type-startseite .main-container-background{
  margin-top: 0;
}

#header_title h1{
  padding: 0;
}

.logo{
  display: block !important;
}
a.logo{
  background-color: #767676 !important;
  padding: 0.5cm;
  display: inline-block;
  img{
    margin-bottom: 0;
  }

}